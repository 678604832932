.container {
    height: 128px;
    width: 100%;
    padding: 16px 0;
    position: relative;
}

.avatar {
    position: absolute;
    left: 16px;
    top: 28px;
    z-index: 9;
}

.input {
    border-radius: 12px !important;
    color: #8E939F;
    padding-left: 56px !important;
    min-height: 56px !important;
    resize: none;
}


.btnContainer {
    display: flex;
    margin-top: 12px;
    justify-content: flex-end;
}
.btn {
    height: 32px !important;
    width: 72px !important;
    font-size: 14px !important;
}
.textLen {
    font-size: 12px;
    color: #696F7F;
    font-family: 'DINPro-Regular' !important;
    line-height: 32px;
    margin-right: 12px;
}
.displayNone {
    display: none;
}
.redText {
    color: #ED220D;
}
