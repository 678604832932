.voterRules {
  display: flex;
  font-size: 12px;
  color: #8E939F;
  padding-left: 20px;

  @media screen and (max-width: 960px) {
    padding-left: 15px;
  }
}

.viewNum {
  margin-right: 40px;
}

.voteWrapper {
  max-width: 556px;
  margin: 20px auto;
  padding: 0 20px;

  @media screen and (max-width: 960px) {
    width: 100%;
    padding: 0 25px;
  }
}