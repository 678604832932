.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  height: 52px;
  width: 52px;
  line-height: 20px;
  justify-content: center;
  align-items: center;
}

.middle {
  line-height: 50px;
}

.count {
  font-size: 12px;
  color: #696F7F;
  line-height: 12px;
  font-family: 'DINPro-Regular' !important;
}

.countLike {
  color: #FA6481
}

/* 新 like 组件样式 */
.fav {
  font-size: 40px;
  color: #D9DBDF;
  width: 26px;
  height: 26px;
  overflow: hidden;
}



.favActive {
  color: #FA6481;
}