@import '@styles/common/baseFn.less';
@borderRadius: 8px;


.hotdetial {
  width: 100%;
  position: relative;
  z-index: 1;
  font-size: 0;

  @media screen and (max-width: 960px) {
    padding: 0 15px;
    overflow: hidden;
    border-radius: @borderRadius;
  }
}

.detialPic {
  width: 100%;
  height: 320px;
  border-radius: @borderRadius;

  @media screen and (max-width: 960px) {
    height: 140px;
  }
}

.detialTitle {
  width: 100%;
  height: 200px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 34%, rgba(0, 0, 0, 0.8) 97%);
  position: absolute;
  bottom: 0;
  left: 0px;

  @media screen and (max-width: 960px) {
    width: calc(100% - 30px);
    left: 15px;
    height: 140px;
    top: 0;
    border-radius: @borderRadius;
    overflow: hidden;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
}

.hotTopiccontent {
  margin-top: 75px;
  font-size: 28px;
  padding: 0px 20px 6px 20px;
  color: #FFFFFF;
  line-height: 40px;
  .ellipsis-multiply();
  .pagination {
    :global {
      .ant-btn {
        width: 20px;
        height: 20px;
        margin-right: 0;
        border: none !important;
      }
    }
  }

  @media screen and (max-width: 960px) {
    font-size: 14px;
    margin-top: 0;
    padding: 0 10px;
    line-height: normal;
  }
}

.viewCount {
  padding-left: 20px;
  font-size: 14px;
  color: #FFFFFF;

  @media screen and (max-width: 960px) {
    font-size: 12px;
    opacity: 0.6;
    padding: 0 10px;
  }
}

.topicDesc {
  padding: 20px;
  font-size: 16px;
  color: #8E939F;
  letter-spacing: 0;
  line-height: 28px;
  white-space: pre-line;

  @media screen and (max-width: 960px) {
    padding: 5px 15px;
    line-height: 28px;
  }
}