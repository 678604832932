.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
}

.btn:disabled {
  opacity: 40%;
}

.primary {
  color: #fff !important;
  background-color: #6F40EE !important;
}

.primary:hover {
  background-image: linear-gradient(rgba(0,0,0,0.08), rgba(0,0,0,0.08));
}

.ghost {
  background-color: rgba(111, 64, 238, 0.1) !important;
  color: #6F40EE !important;
}

.ghost_grey {
  background-color: #F6F8FB !important;
  color: #8E939F !important;
}

.ghost_grey:hover {
  background-image: linear-gradient(rgba(0,0,0,0.01), rgba(0,0,0,0.01));
}

.tint {
  background-color: #F6F8FB !important;
  color: rgba(111, 64, 238, 0.4) !important;
}
.ghost:hover {
  background-image: linear-gradient(rgba(0,0,0,0.01), rgba(0,0,0,0.01));
}

.sizeXl {
  width: 112px;
  height: 40px;
}

.sizeLg {
  width: 72px;
  height: 32px;
}

.sizeMd {
  width: 58px;
  height: 28px;
}

.hidden {
  display: none;
}

@media screen and (max-width: 960px){
  .sizeMd {
    width: 50px;
    height: 24px;
  }
}