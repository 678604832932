.hotTopicDetial {
    width: 100%;
    margin-top: 32px;
}
.hotTopicCont {
    overflow: hidden;
    background:#fff;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
}
.hotTopicCont {
    margin-top: 16px;
}
.hotPeoplePage {
    display: flex;
    justify-content: space-between;
}
.hotTopicTitle {
    line-height: 20px;
    font-family: DINPro-Bold;
    font-weight: Bold;
    font-size: 14px;
}
.pagination :global .ant-btn {
    width: 20px;
    height: 20px;
    margin-right: 0;
    border: none !important;
}
.hotTopicList {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    padding: 10px;
    border: 2px solid #F6F8FB;
    border-radius: 45px;
    font-size: 14px;
    color: #434B5F;
    margin-right: 12px;
    margin-bottom: 7px;
    cursor: pointer;
}

.hotTopicList:hover {
    background-color: #F6F8FB;
}

.attention :global .ant-btn > span {
    font-size: 12px;
}
@media screen and (max-width: 960px) {
    .h5Container {
        width: 100%;
        margin-top: 15px;
    }
    .h5List {
        overflow: auto;
    }
    .h5Row {
        display: flex;
    }
    .hotTopicList {
        flex-shrink: 0;
    }
    .h5List::-webkit-scrollbar {
      display: none;
    }
}