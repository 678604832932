@square-color: #2B60E6;
@opposition-color: #F0506C;

.voterDetial {
  width: 100%;
}

.voterName {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.voterNameLeft {
  color: @square-color;
}

.voterNameRight {
  color: @opposition-color;
}

.percentCont {
  display: flex;
}

.percentDataleft {
  font-size: 20px;
  color: @square-color;
}

.pollbackRightAnm {
  animation: voterRight 1s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.pollbackLeftAnm {
  animation: voterLeft 1s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.percentDataright {
  font-size: 20px;
  color: @opposition-color;
}

.percentNum {
  margin-top: 3px;
  width: 100%;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  height: 20px;
  position: relative;
  --position-left: -23px;
  --position-right: -23px;

  .left,
  .right {
    position: absolute;
    height: 20px;
    border-radius: 10px;
    border-radius: 4px;
    transform: skewX(-25deg);
  }

  .left {
    background-color: @square-color;
    left: var(--position-left);
  }

  .right {
    background-color: @opposition-color;
    right: var(--position-right);
  }
}


@keyframes voterRight {
  0% {
    width: calc(50% - var(--position-right) - 4px);
  }

  100% {
    width: var(--voter-right);
  }
}

@keyframes voterLeft {
  0% {
    width: calc(50% - var(--position-left) - 4px);
  }

  100% {
    width: var(--voter-left);
  }
}

@media screen and (max-width: 960px) {
  .percentDataleft {
    margin-right: 4px;
    font-size: 16px;
  }

  .percentDataright {
    margin-left: 4px;
    font-size: 16px;
  }
}