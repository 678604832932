.topicSection {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  display: inline-block;
  width: 100%;
}
.topicSectionA {
  display: flex;
  width: 100%;
}
.topicSectionBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #F9F9FA;
  height: 42px;
  border-radius: 45px;
  padding-left: 13px;
  padding-right: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.topicTagImage {
  width: 25px;
  height: 22px;
  justify-content: center;
  align-items: center;
}
.topicTagText {
  font-size: 14pz;
  color: #6F40EE;
  margin-left: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.closeIcon{
  margin-left: 8px;
  display: flex;
  justify-content: center;
}