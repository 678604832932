.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
}
.container>div:first-child {
    flex: 1;
}
.input {
    height: 56px;
    color: #8E939F;
}
.input :global .ant-input {
    color: #8E939F;
}
.btnContainer {
    display: flex;
    flex-direction: column;
    width: 72px;
    margin-left: 12px;
}
.btn {
    height: 32px !important;
    width: 72px !important;
    font-size: 14px !important;
}
.textLen {
    font-size: 12px;
    color: #696F7F;
    font-family: 'DINPro-Regular' !important;
    line-height: 24px;
    height: 24px;
    text-align: right;
}
.displayNone {
    display: none;
}
.redText {
    color: #ED220D;
}
