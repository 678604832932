/* 投票 */
.voterChoice {
  width: 480px;
  margin: 20px auto;
  display: flex;
  position: relative;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
}

.bg {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.voterChoiceLeft {
  background-image: url(~@images/btn1.webp);
}

.voterChoiceRight {
  background-image: url(~@images/btn2.webp);
}

.voterChoiceLeft,
.voterChoiceRight {
  position: relative;
  cursor: pointer;
  font-size: 0;
  flex: 1;
  height: 50px;
  .bg;
}

.pollText {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 1;
  font-size: 14px;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 960px) {
    width: 100%;
    font-size: 12px;
  }
}

.voterChoiceMiddle {
  position: absolute;
  margin: 0 auto;
  width: 95px;
  height: 50px;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  background-image: url(~@images/VS.webp);
  .bg;
  z-index: 2;
  transform-origin: 50% 50%;

  @media screen and (max-width: 960px) {
    transform: translateX(-50%) scale(0.75);
  }
}