.container {
    display: flex;
    flex-direction: column;
    height: 280px;
    position: fixed;
    left: calc(100% - 50% - 600px - 52px - 24px);
    top: 252px;
}
.items {
    width: 52px;
    height: 52px;
    border-radius: 52px;
    background-color: #fff;
    box-shadow: 0 2px 24px 0 rgba(200,201,204,0.5);
    cursor: pointer;
}
.items:not(:last-child) {
    margin-bottom: 24px;
}

@media (max-width: 1360px) {
    .container {
        left: 5px;
    }
}

@media (max-width: 1320px) {
    .container {
        left: calc(100% - 50% - 600px - 52px - 24px + 60px);
    }
}

@media (max-width: 1200px) {
    .container {
        left: 5px;
    }
}
