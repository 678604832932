.container {
  margin-top: 12px;
}
.tradingInfoOrderView {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
}
.tradingInfoOrderBoldText {
  font-weight: 700;
}
.greenBgc {
  background-color: rgba(30, 174, 69,0.1);
  color: #1EAE45;
}
.redBgc {
  background-color: rgba(255, 55, 80, 0.1);
  color: #FF3750;
}
.mt24 {
  margin-top: 24px;
  margin-bottom: 8px;
}