.hotPeople {
  overflow: hidden;
  /* background: rgba(111, 64, 238, 0.1); */
  background: #fff;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  height: 440px;

  @media screen and (max-width: 960px) {
    width: 100%;
    height: 200px;
  }
}


/* 热门人物 */
.hotPeoplePage {
  display: flex;
  justify-content: space-between;
}

.hotPersonTitle {
  line-height: 20px;
  font-family: DINPro-Bold;
  font-weight: Bold;
  font-size: 14px;
}

.pagination :global .ant-btn {
  width: 20px;
  height: 20px;
  margin-right: 0;
  border: none !important;
}

.hotPerson {
  height: calc(796px - 70px);
  overflow-y: auto;

  @media screen and (max-width: 960px) {
    display: flex;
    width: auto;
    overflow-y: auto;
    position: relative;
    margin-bottom: 0;
    margin-top: 20px;
    height: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.hotPersonCont {
  height: 40px;
  margin-top: 24px;
  display: flex;

  a {
    height: 40px;
  }

  .followBtn {
    padding-top: 4px;

    :global {
      .ant-btn {
        width: 58px;
        height: 28px;
        font-size: 12px;
      }
    }

    @media screen and (max-width: 1180px) {
      .btn {
        width: 72px;
      }
    }
  }

  @media screen and (max-width: 960px) {
    flex-shrink: 0;
    display: block;
    text-align: center;
    margin-right: 24px;
    margin-top: 0;
    width: 60px;
    height: auto;

    &:last-child {
      margin-right: 0;
    }
  }
}

.hotPersonDescribe {
  margin: 0 16px;
  width: 180px;

  @media screen and (max-width: 960px) {
    margin: 16px 0 7px 0;
    width: 100%;
  }
}

.hotPersonName {
  font-size: 14px;
  padding-top: 2px;

  @media screen and (max-width: 960px) {
    overflow: hidden;
    max-width: 72px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #696F7F;
  }
}

.describe {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #696F7F;

  @media screen and (max-width: 1180px) {
    width: 165px;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
}