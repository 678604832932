.container {
  z-index: 10;
}

// 主入口大小
.mainSize {
  width: 52px;
  height: 52px;

  @media screen and (max-width: 960px) {
    width: 48px;
    height: 48px;
  }
}

// 发长文与发帖
.btn {
  display: none;
  flex-direction: column;
  font-size: 10px;
  color: #8E939F;

  &::before {
    width: 40px;
    height: 40px;
    background-color: #FFFFFF;
    background-image: url(~@images/icon/post.webp);
    background-repeat: no-repeat;
    background-size: 23px 23px;
    background-position: center;
    cursor: pointer;
  }
}

// 主入口
.pen {
  display: flex;
  border-radius: 50%;
  background-color: #6F40EE;
  .mainSize;

  &:hover {
    @media screen and (min-width: 961px) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08));
    }
  }

  &::before {
    .mainSize;
    background-image: url(~@images/icon/pen_white.webp);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center;
    cursor: pointer;

    @media screen and (max-width: 960px) {
      background-size: 18px 18px;
    }
  }
}

// 发长文发帖入口 icon 布局
.btn,
.pen {
  align-items: center;
  justify-content: center;
}

.btn::before,
.pen::before {
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  content: '';
}

.main {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  &:hover>.btn {
    @media screen and (min-width: 961px) {
      display: flex;
    }
  }
}

.penBlack {
  margin-top: 12px;
  margin-bottom: 12px;
}

.penBlack::before {
  background-image: url(~@images/icon/pen_black.webp);
}

.h5Close {
  display: none;
}

// H5 的样式
.h5Container {
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);

  .pen {
    display: none;
  }

  .main {
    height: 180px;
    background-color: #FFFFFF;
    border-radius: 18px 18px 0 0;
    position: absolute;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 100px;
    z-index: 12;

    .h5Close {
      display: inline-block;
      position: absolute;
      right: 11px;
      top: 14px;
      color: #696F7F;
      font-size: 22px;
    }

    .btn {
      display: flex;

      &::before {
        box-shadow: none;
        width: 50px;
        height: 50px;
        background-color: #000;
        background-image: url(~@images/community/pubslishLongLext.webp);
      }
    }

    .penBlack {
      &::before {
        background-color: #6F40EE;
        background-image: url(~@images/community/pubslishPost.webp);
      }
    }
  }
}

.bodyOverflow {
  overflow: hidden;
}